import styled from '@emotion/styled';
import { DashboardLayout } from '@layouts/dashboard';
import CircularProgress from '@mui/material/CircularProgress';

const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(p) => p.theme.colors.gray400};
`;

const IndexPage = () => {
  return (
    <StyledLoadingContainer>
      <StyledCircularProgress />
    </StyledLoadingContainer>
  );
};

IndexPage.getLayout = (page: JSX.Element) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default IndexPage;
